import { CrmFrontMessage, MessagesGroupResult } from '../models/message.model';

export function generateMsgDayGroupKey(message: CrmFrontMessage) {
  const createdDate = new Date(message.createdDate!);
  return new Date(
    createdDate.getFullYear(),
    createdDate.getMonth(),
    createdDate.getDate()
  ).getTime();
}

export function groupMessageByDate(
  messags: Array<CrmFrontMessage>
): MessagesGroupResult[] {
  return messags.reduce((acc, message) => {
    const dateKey = generateMsgDayGroupKey(message);
    const foundKey = acc.find((x) => x.key === dateKey);
    if (foundKey) {
      foundKey.messages.push(message);
    } else {
      acc.push({
        key: dateKey,
        messages: [message],
      });
    }
    return acc;
  }, [] as MessagesGroupResult[]);
}
